'use client'

import classNames from 'classnames'
import {
  CloseCircle,
  I3Dcube,
  InfoCircle,
  TickCircle,
  Timer,
  Warning2
} from 'iconsax-react'
import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

export enum ProcessStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  PENDING = 'pending',
  EMPTY = 'empty',
  INFO = 'info',
  WARNING = 'warning'
}

export type StatusProps = {
  className?: string
  status: ProcessStatus
  size?: 'small' | 'default'
  icon?: ReactNode
}

const Status = ({
  className,
  status,
  size = 'default',
  icon: customIcon
}: StatusProps) => {
  const classes = useStyles()
  const icon = {
    success: <TickCircle size="24" />,
    error: <CloseCircle size="24" />,
    pending: <Timer size="24" />,
    empty: <I3Dcube size="24" />,
    warning: <Warning2 size="24" />,
    info: <InfoCircle size="24" />
  }

  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center rounded-full',

        {
          [`h-[56px] w-[56px]`]: size === 'default',
          [`h-[44px] w-[44px]`]: size === 'small',
          '!bg-green bg-opacity-[0.16] !text-white':
            status === ProcessStatus.SUCCESS,
          '!bg-yellow bg-opacity-[0.16] !text-black':
            status === ProcessStatus.PENDING,
          '!bg-red bg-opacity-[0.16] !text-white':
            status === ProcessStatus.ERROR,
          '!bg-gray-200': status === ProcessStatus.EMPTY,
          '!bg-gray-400 !text-white': status === ProcessStatus.INFO,
          '!bg-yellow !text-black': status === ProcessStatus.WARNING
        },
        className
      )}
    >
      {customIcon ?? icon[status]}
    </span>
  )
}

const useStyles = createUseStyles({})

export default Status
