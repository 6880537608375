'use client'

import Badge from '@/components/Badge'
import Button from '@/components/Button'
import IconButton from '@/components/IconButton'
import Image from '@/components/Image'
import Link from '@/components/Link'
import Skeleton from '@/components/Skeleton'
import useCurrentUser from '@/hooks/useCurrentUser'
import useModal from '@/hooks/useModal'
import useTranslate from '@/hooks/useTranslate'
import { Brand } from '@/types/brand'
import { formatToPrice } from '@/utils/utils'
import classNames from 'classnames'
import { AddCircle, Bag2, Heart } from 'iconsax-react'
import { useState } from 'react'

export type ProductProps = {
  id: number
  url: string
  title: string
  thumbnail: string
  brand: Brand
  className?: string
  price: number
  discountPercentage?: number
  showAddToCollection?: boolean
  onAddToFavorites?: () => void
  onAddToCart?: () => void
}

const Product = ({
  id,
  title,
  thumbnail,
  brand,
  price,
  discountPercentage,
  className,
  url,
  onAddToFavorites,
  onAddToCart
}: ProductProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const t = useTranslate()
  const { open } = useModal()
  const { currentUser } = useCurrentUser()

  const handleAddToCollection = () => {
    if (!!currentUser) {
      open('ADD_TO_COLLECTION', { id, title })
    } else {
      open('AUTH')
    }
  }

  return (
    <div
      className={classNames(
        'group overflow-hidden border border-gray-300 p-4 font-firago xl:relative xl:pb-[130%] xl:ease-in-out',
        className
      )}
    >
      <div className="w-full xl:absolute xl:left-0 xl:top-0">
        <div className="p-0 xl:px-8 xl:pt-8 xl:transition-all xl:duration-300 xl:ease-in-out xl:group-hover:p-2.5 xl:group-hover:pb-0">
          <div className="relative mb-3.5 overflow-hidden rounded-lg xl:pb-[100%] xl:transition-all xl:duration-300 xl:ease-in-out xl:group-hover:pb-[78%]">
            <div className="xl:absolute xl:left-0 xl:top-0 xl:h-full xl:w-full">
              <Link href={url} className="relative block xl:h-full xl:w-full">
                {!imageLoaded && (
                  <Skeleton type="image" className="!absolute md:!relative" />
                )}
                <Image
                  height={300}
                  width={300}
                  src={thumbnail}
                  alt={title}
                  className="block object-cover xl:h-full !w-full max-lg:!h-[250px]"
                  placeholder="empty"
                  onLoad={() => setImageLoaded(true)}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="px-0 xl:px-8">
          <h5>
            <Link
              href={`/brand/${brand.slug}/profile`}
              className="pb-1 !text-[11px] text-gray-400"
            >
              {brand.name}
            </Link>
          </h5>
          <h2>
            <Link href={url} className="block min-h-[41px] !text-sm">
              {title}
            </Link>
          </h2>
          <div className="flex items-center pt-2">
            {discountPercentage && (
              <Badge className="mr-2" type="danger">
                -{discountPercentage}%
              </Badge>
            )}
            <span className="text-base text-black">
              {formatToPrice(
                discountPercentage
                  ? price - (price * discountPercentage) / 100
                  : price
              )}{' '}
              ₾
            </span>
            {discountPercentage && (
              <span className="mx-2 block h-[4px] w-[4px] rounded-full bg-gray-400" />
            )}
            {discountPercentage && (
              <span className="text-base text-gray-400 line-through">
                {formatToPrice(price)} ₾
              </span>
            )}
          </div>
          <div className="flex items-center gap-2 pt-3 transition-all duration-500 ease-in-out xl:opacity-0 xl:group-hover:opacity-100">
            <Button
              onClick={onAddToCart}
              type="default"
              size="small"
              block
              icon={<Bag2 size={20} />}
            >
              {t('common.add_to_cart')}
            </Button>
            <IconButton
              onClick={onAddToFavorites}
              type="secondary"
              size="small"
              icon={<Heart size={20} />}
            />
            {!!currentUser && (
              <IconButton
                type="secondary"
                size="small"
                onClick={handleAddToCollection}
                icon={<AddCircle size={20} />}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
