'use client'

import IconButton from '@/components/IconButton'
import Product from '@/components/Product'
import Skeleton from '@/components/Skeleton'
import useCurrentUser from '@/hooks/useCurrentUser'
import useModal from '@/hooks/useModal'
import useTranslate from '@/hooks/useTranslate'
import { getProductThumbnail } from '@/utils/utils'
import classNames from 'classnames'
import { ArrowLeft, ArrowRight, ReceiptSearch } from 'iconsax-react'
import { PropsWithChildren, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'
import 'swiper/css'
import { Autoplay, FreeMode, Keyboard } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import Status, { ProcessStatus } from '../Status'

export type ProductCarouselProps = PropsWithChildren<{
  className?: string
  title?: string
  products: any[]
  slidesPerView?: number
  slidesPerGroup?: number
  fixedWidth?: boolean
  showSkeleton?: boolean
}>

const ProductCarousel = ({
  className,
  products,
  title,
  slidesPerView = 4,
  slidesPerGroup = 2,
  fixedWidth = true,
  showSkeleton = false
}: ProductCarouselProps) => {
  const classes = useStyles()
  const swiperRef = useRef<SwiperRef>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiperLoaded, setSwiperLoaded] = useState(false)
  const [swiperEnded, setSwiperEnded] = useState(false)
  const handleNext = () => swiperRef.current?.swiper.slideNext()
  const handlePrev = () => swiperRef.current?.swiper.slidePrev()
  const currentUser = useCurrentUser()
  const { open } = useModal()
  const t = useTranslate()

  const handleAddToCollection = (id: any) => {
    open('ADD_TO_COLLECTION', { productId: id })
  }

  return (
    <div className="">
      <div
        className={classNames(
          'mb-5 flex items-center justify-between font-firago sm:mb-7',
          className
        )}
      >
        <h2 className="text-base font-medium sm:text-xl">{title}</h2>
        {!!products?.length && (
          <div className="hidden sm:flex sm:items-center sm:gap-3">
            <IconButton
              type="ghost"
              size="small"
              icon={<ArrowLeft size={20} />}
              className={classNames(
                '!hover:border-black !border !bg-transparent',
                {
                  '!border-gray-300': !currentIndex || !swiperLoaded,
                  '!border-black': !!currentIndex
                }
              )}
              onClick={handlePrev}
              disabled={!currentIndex || !swiperLoaded}
            />
            <IconButton
              type="ghost"
              size="small"
              icon={<ArrowRight size={20} />}
              className={classNames(
                '!hover:border-black !border !bg-transparent',
                {
                  '!border-gray-300':
                    swiperEnded ||
                    !swiperLoaded ||
                    products?.length <= slidesPerView,
                  '!border-black': !swiperEnded
                }
              )}
              onClick={handleNext}
              disabled={
                swiperEnded ||
                !swiperLoaded ||
                products?.length <= slidesPerView
              }
            />
          </div>
        )}
      </div>
      {!swiperLoaded && showSkeleton && (
        <div>
          <div className="flex w-[162vw] sm:w-full">
            {new Array(4).fill(null)?.map((_, index) => (
              <Skeleton
                key={index}
                className={classNames('!w-[81vw] sm:w-full', {
                  '!border-l-[0px]': index > 0,
                  '!border-l-[1px]': index === currentIndex,
                  'max-lg:hidden': index > 1
                })}
                type="product"
              />
            ))}
          </div>
        </div>
      )}

      {!!products?.length && (
        <div className="-mx-5 sm:mx-0">
          <Swiper
            modules={[Keyboard, Autoplay, FreeMode]}
            className={classNames(classes.slider)}
            wrapperClass="!px-5 sm:!px-0 !w-initial lg:border-0"
            ref={swiperRef}
            onSlideChange={({ activeIndex, isEnd }) => {
              if (!isMobile) {
                setCurrentIndex(activeIndex)
                setSwiperEnded(isEnd)
              }
            }}
            speed={isMobile ? 100 : 600}
            keyboard
            direction="horizontal"
            grabCursor
            onInit={() => setSwiperLoaded(true)}
            slidesPerView="auto"
            spaceBetween={0}
            freeMode={true}
            cssMode={isMobile}
            autoplay={
              !isMobile && {
                delay: 4000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
                reverseDirection: false
              }
            }
            breakpoints={{
              1024: {
                rewind: true,
                slidesPerView,
                slidesPerGroup,
                freeMode: false
              }
            }}
          >
            {products?.map(
              (
                {
                  id,
                  title,
                  brand,
                  assets = [],
                  price,
                  slug,
                  discountPercentage
                },
                index
              ) => (
                <SwiperSlide
                  className={classNames(
                    'border-l-[1px] border-gray-300 lg:border-0 [&:last-child>*]:border-r-[1px]',
                    {
                      'sm:!w-[325px]': fixedWidth
                    }
                  )}
                  key={id}
                >
                  <Product
                    id={id}
                    brand={brand}
                    title={title}
                    thumbnail={getProductThumbnail(assets)}
                    price={price}
                    discountPercentage={discountPercentage}
                    url={`/product/${slug}`}
                    className={classNames('border-x-0 lg:border-x-[1px]', {
                      'lg:border-l-[0px]': index > 0,
                      'lg:border-l-[1px]': index === currentIndex
                    })}
                    showAddToCollection={!!currentUser}
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      )}

      {!products?.length && (
        <div className="flex flex-col items-center justify-center py-8">
          <Status
            size="default"
            icon={<ReceiptSearch size="24" />}
            status={ProcessStatus.EMPTY}
          />
          <span className="text-sm mt-4">
            {t('common.no_products_available')}
          </span>
        </div>
      )}
    </div>
  )
}

const useStyles = createUseStyles({
  slider: {
    '& .slick-track': {
      display: 'flex'
    }
  }
})

export default ProductCarousel
